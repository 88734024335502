<template>
    <div class="overflow-auto border">
      <div class="border overflow-auto">
        <Dashboard
          v-if="uppy"
          :uppy="uppy"
          :props="{
            inline: true,
            showProgressDetails: true,
            hideUploadButton: false,
            width: 'auto',
          }"
        />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, inject, defineEmits } from 'vue';
  import Uppy from '@uppy/core';
  import AwsS3 from '@uppy/aws-s3';
  import GoldenRetriever from '@uppy/golden-retriever';
  import { Dashboard } from '@uppy/vue';
  import '@uppy/core/dist/style.css';
  import '@uppy/dashboard/dist/style.css';
  
  const $call = inject('$call');
  const emit = defineEmits(['uploadStarted', 'uploadComplete', 'uploadError']);
  const uppy = ref(null);
  const errorMessage = ref(null);
  
  onMounted(() => {
  
    uppy.value = new Uppy({
      id: 'dailyObservationUploader',
      restrictions: {
        maxNumberOfFiles: null, // No limit on files
        allowedFileTypes: ['image/*'], // Allow only images
      },
      autoProceed: false, // User must click upload
      debug: true,
    })
      .use(GoldenRetriever, {
        serviceWorker: true, // Uses Service Workers for reliability
        indexedDB: {
          maxFileSize: 5 * 1024 * 1024 * 1024, // Supports up to 5GB in IndexedDB
        },
        prefix: 'dailyObservation',
      })
      .use(AwsS3, {
        companionUrl: null, // Not using companion server
        getUploadParameters: async (file) => {
          try {
            const response = await $call(
              'curriculum_planner.curriculum_planner.doctype.daily_observations_v2.api.get_presigned_image_upload_url',
              { content_type: file.type } // Pass the content type to the backend
            );
  
            if (!response.success) {
              throw new Error(response.error);
            }
            return {
              method: 'PUT',
              url: response.presigned_url,
              fields: {}, // No additional fields needed for PUT
              headers: {
                'Content-Type': file.type || 'application/octet-stream', // Set the file type
              },
            };
          } catch (error) {
            errorMessage.value = `Error fetching upload parameters: ${error.message}`;
            throw error;
          }
        },
      });
  
    // Uppy event listeners
    uppy.value.on('upload', () => {
      emit('uploadStarted');
      errorMessage.value = null; // Clear previous errors
    });
  
    uppy.value.on('upload-progress', (file, progress) => {
      console.log(`Uploading ${file.name}: ${progress.bytesUploaded}/${progress.bytesTotal}`);
    });
  
    uppy.value.on('upload-success', (file, response) => {
      console.log(`Upload successful for ${file.name}:`, response);
      emit('uploadComplete', response);
    });
  
    uppy.value.on('upload-error', (file, error) => {
      errorMessage.value = `Upload failed for ${file.name}: ${error.message}`;
      console.error('Upload error:', file.name, error);
      emit('uploadError', { file, error });
    });
  
    uppy.value.on('file-added', () => {
      emit('dashboardStatusChanged', uppy.value.getFiles());
    });

    uppy.value.on('file-removed', () => {
      emit('dashboardStatusChanged', uppy.value.getFiles());
    });

    uppy.value.on('complete', () => {
      emit('dashboardStatusChanged', uppy.value.getFiles());
    });
      
    uppy.value.on('error', (error) => {
      console.error('Uppy error:', error.message);
      errorMessage.value = 'An unexpected error occurred. Please try again.';
    });
  });
  </script>
  
  <style scoped>
  /* Custom Dashboard styles */
  .uppy-Dashboard-inner {
    /* height: 300px !important; */
    
  }
  </style>
  