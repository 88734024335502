<template>
  <div :class="cn('p-4 overflow-y-visible flex-auto flex flex-col gap-4 text-gray-800 dark:text-gray-400',
  props.pageNum !== props.componentPageNum && `hidden`)" >
    <p class="font-medium text-sm md:text-base">
      Upload Images(optional)
    </p>
    <observationPhotoUpload  v-if="shouldShowUppy" @uploadComplete="handleUploadComplete"  @dashboardStatusChanged="handleDashboardStatusChanged" ></observationPhotoUpload>
  </div>

</template>

<script setup>
import {inject, ref, defineEmits, computed } from 'vue';
import { cn } from '@/components/ui/utils.js'
// import { createResource } from 'frappe-ui'
import { findValueByName } from '@/lib/utils.js'

import observationPhotoUpload from '../../../videoUpload/observationPhotoUpload.vue';




const emit = defineEmits(['uploadStarted', 'uploadComplete', 'uploadError']);
const props = defineProps([
  'pageNum', 'observation', 'componentPageNum'
])

const isFormSubmittable = ref(true);

// Replace showUppy ref with computed property
const shouldShowUppy = computed(() => {
  return props.pageNum === props.componentPageNum;
});

const handleDashboardStatusChanged = (files) => {
  // Check if there are files in the dashboard that are not fully uploaded
  isFormSubmittable.value = files.every(file => file.progress.uploadComplete) || files.length === 0;

  // Emit the updated value to the parent
  emit('formSubmittableStatusChanged', isFormSubmittable.value);
};

// Handle response from the child component
const handleUploadComplete = (response) => {
  emit('uploadComplete', response);
}


const userDetails = inject('userDetails')
</script>